import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"

const  Providers=({ children }) =>{

  return (
      <GoogleReCaptchaProvider reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}>
        {children}
      </GoogleReCaptchaProvider>
  )
}
export default Providers;