import Head from "next/head";
import Link from "next/link";
import React from "react";
import GoTop from "./GoTop";
import Preloader from "./Preloader";
import Providers from "./Providers";

const Layout = ({ children, props: { seo }, ...pageProps }) => {
  const CANONICAL_SITE_URL = process.env.NEXT_PUBLIC_CANONICAL_SITE_URL;
  const CANONICAL_NZ_SITE_URL = process.env.NEXT_PUBLIC_CANONICAL_NZ_SITE_URL;
  const isAU = process.env.NEXT_PUBLIC_SITE_IS_AU === "true";
  const buildImage =
    process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA ??
    process.env.NEXT_PUBLIC_BUILD_IMAGE; //config.buildImage;
  const defaultMetaData =
    "Choose Ryco Filters for the best oil filters, air filters, fuel filters and vehicle products. The leading filter experts in Australia and New Zealand.";
  const defaultTitle =
    "Fuel filters | Air filters | Oil filters - Ryco Filters";

  const {
    title,
    metadata,
    pic,
    keywords,
    socialTitle,
    socialIsArticle,
    socialDescription,
    url,
    isNzOnly,
    extraHeaders,
  } = seo ? seo : { title: defaultTitle, metadata: defaultMetaData };

  const local_title = title ? title : defaultTitle;
  const local_metadata = metadata ? metadata : defaultMetaData;
  const local_keywords = keywords ? keywords : null;
  const local_pic = pic
    ? pic
    : "https://cdn.rycofilters.com/ryco/next/ryco_social.png";

  const local_social_title = socialTitle ? socialTitle : local_title;
  const local_social_description = socialDescription
    ? socialDescription
    : local_metadata;
  const local_url = url ? url : null;
  const local_isNzOnly = isNzOnly ? isNzOnly : false;
  let local_site_url = `${CANONICAL_SITE_URL}${local_url}`;
  if (local_isNzOnly) {
    local_site_url = `${CANONICAL_NZ_SITE_URL}${local_url}`;
  }
  // Preloader
  const [loader, setLoader] = React.useState(true);
  React.useEffect(() => {
    setTimeout(() => setLoader(false), 1500);
  }, []);

  let localExtraHeaders = extraHeaders ? extraHeaders : null;
  if (extraHeaders) {
    if (extraHeaders == "") {
      localExtraHeaders = null;
    }
  }

  const auChat = `
  ////new live chat script for AU
  (function() {
    var script  = document.createElement('script');
    script.type = 'text/javascript';
    script.src  = "https://webchat.mitel.io/bootstrapper.js?accountid%3DYjZkNmRmNmUtNmZhNy00YzU4LTg5MmUtMjAwOGM0YzAyN2Rm%26chatname%3DQ2hhdCB0byB1cyBvbmxpbmU%3D";
    document.head.appendChild(script);
})();
  `;
  const nzChat = `
  ////new live chat script for NZ
  (function() {
    var script  = document.createElement('script');
    script.type = 'text/javascript';
    script.src  = "https://webchat.mitel.io/bootstrapper.js?accountid%3DYjZkNmRmNmUtNmZhNy00YzU4LTg5MmUtMjAwOGM0YzAyN2Rm%26chatname%3DQ2hhdCBOWg%3D%3D";
    document.head.appendChild(script);
})();
  `;

  return (
    <>
      <Head>
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1, shrink-to-fit=no'
        />
        <title>{local_title}</title>
        {local_keywords && <meta name='keywords' content={local_keywords} />}
        <meta name='description' content={local_metadata} />
        <meta
          name='og:title'
          property='og:title'
          content={local_social_title}
        ></meta>
        <meta
          name='og:description'
          property='og:description'
          content={local_social_description}
        ></meta>
        <meta name='og:image' property='og:image' content={local_pic}></meta>
        <meta name='twitter:card' content={local_social_title}></meta>
        {socialIsArticle && <meta property='og:type' content='article' />}
        {local_url && <Link rel='canonical' href={local_site_url}></Link>}
        {localExtraHeaders && (
          <script dangerouslySetInnerHTML={{ __html: localExtraHeaders }} />
        )}
      </Head>
      <Providers>{children}</Providers>

      {loader ? <Preloader /> : null}

      <GoTop scrollStepInPx='100' delayInMs='10.50' />
      <script
        dangerouslySetInnerHTML={{ __html: `<!-- BUILD: ${buildImage} -->` }}
      />
      {/* {isAU && <script
        dangerouslySetInnerHTML={{ __html: auChat }}
      />}
      {!isAU && <script
        dangerouslySetInnerHTML={{ __html: nzChat }}
      />} */}
      <script
        type='text/javascript'
        id='hs-script-loader'
        async
        defer
        src='https://js.hs-scripts.com/21930911.js'
      ></script>
    </>
  );
};

export default Layout;
