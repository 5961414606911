import * as style from "../styles/scss/common.scss";
//import * as vimeo from "../styles/vi-lazyload.min.css";

import { useRouter } from "next/router";
import { useEffect } from "react";
import Layout from "../components/_App/Layout";
import ErrorBoundary from "../components/ErrorBoundary";

import "react-modal-video/scss/modal-video.scss";

import * as ga from "../lib/ga";
//import Honeybadger from '@honeybadger-io/js'

// https://docs.honeybadger.io/lib/javascript/reference/configuration.html
//Honeybadger.configure({
//  apiKey: process.env.HONEYBADGER_API_KEY,
//  revision: process.env.HONEYBADGER_REVISION,
//  environment: process.env.NODE_ENV,
//  projectRoot: 'webpack://_N_E/./',

// Uncomment to report errors in development:
//  reportData: true,
//})

function hoverMenu() {
  // make it as accordion for smaller screens
  if (window.innerWidth > 992) {
    document
      .querySelectorAll(".navbar .nav-item")
      .forEach(function (everyitem) {
        everyitem.addEventListener("mouseover", function (e) {
          let el_link = this.querySelector("a[data-bs-toggle]");

          if (el_link != null) {
            let nextEl = el_link.nextElementSibling;
            el_link.classList.add("show");
            nextEl.classList.add("show");
          }
        });
        everyitem.addEventListener("mouseleave", function (e) {
          let el_link = this.querySelector("a[data-bs-toggle]");

          if (el_link != null) {
            let nextEl = el_link.nextElementSibling;
            el_link.classList.remove("show");
            nextEl.classList.remove("show");
          }
        });
      });
  }
  // end if innerWidth
}
function MyApp({ Component, pageProps }) {
  const router = useRouter();
  const isProduction = process.env.NODE_ENV === "production";
// console.log("is_production", isProduction);
  //FB pixel
  useEffect(() => {
    if (isProduction) {
      import("react-facebook-pixel")
        .then((x) => x.default)
        .then((ReactPixel) => {
          ReactPixel.init("570331187482531"); // facebookPixelId
          ReactPixel.pageView();

          router.events.on("routeChangeComplete", () => {
            ReactPixel.pageView();
          });
        });
    }
  }, [isProduction, router.events]);

  useEffect(() => {
    import("bootstrap/dist/js/bootstrap");

    hoverMenu();

    const handleRouteChange = (url) => {
      if (isProduction) {
        //only in production we log events
        ga.pageview(url);
      }

      hoverMenu();
    };

    //When the component is mounted, subscribe to router changes
    //and log those page views
    router.events.on("routeChangeComplete", handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [isProduction, router.events]);

  // Workaround for https://github.com/zeit/next.js/issues/8592
  const { err } = pageProps;
  const modifiedPageProps = { ...pageProps, err };


  return (
    <>
          <ErrorBoundary>
  

      <Layout props={modifiedPageProps}>
        <Component {...modifiedPageProps} />
      </Layout>
     
      </ErrorBoundary>
    </>
  );
}

export default MyApp;
