// log the pageview with their URL
export const pageview = (url) => {
  const isAU = process.env.NEXT_PUBLIC_SITE_IS_AU;
  const analyticsId = isAU
    ? process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS
    : process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_NZ;
  window.gtag("config", analyticsId, {
    page_path: url,
  });
};

// log specific events happening.
export const event = ({ action, params }) => {
  window.gtag("event", action, params);
};
